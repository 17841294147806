<template>
  <div>
    <div
      v-if="Object.keys(site).length === 0"
      class="d-flex justify-content-center mb-1"
    >
      <b-spinner label="Loading..." />
    </div>
    <div v-else>
      <breadcrumbs
        :breadcrumb-items="breadcrumbItems"
      />
      <UserToolbar
        v-if="account && Object.keys(account).length !== 0"
        :account="account"
      />
      <b-card>
        <SiteToolbar
          v-if="Object.keys(site).length !== 0"
          :site="site"
          closing
        />
      </b-card>
      <b-form @submit.prevent>
        <validation-observer
          v-slot="{ invalid }"
          ref="validationSiteRules"
        >
          <b-tabs
            pills
          >
            <b-tab active>
              <!-- title -->
              <template #title>
                <feather-icon
                  icon="SettingsIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">{{ $t('Main settings') }}</span>
              </template>
              <b-card>
                <div class="head-card">
                  <div class="head-card-top">
                    <h4>{{ $t('Main settings') }}</h4>
                    <!-- <feather-icon
                      v-b-tooltip.hover.top="$t('Close')"
                      icon="XIcon"
                      size="18"
                      class="toggle-close cursor-pointer"
                      @click="$router.go(-1)"
                    /> -->
                  </div>
                  <hr>
                </div>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('sites.sitetitle')"
                      label-for="i-title"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('sites.sitetitle')"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length == 0 || 'is-invalid'"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="LayoutIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-title"
                            v-model="site.title"
                            placeholder="Site`s name"
                            :state="errors.length > 0 ? false:null"
                            autocomplete="title"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      :label="$t('sites.sitedomain')"
                      label-for="i-domain"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('sites.sitedomain')"
                        :rules="{ required: true, regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,10}(:[0-9]{1,5})?(\/.*)?$/ }"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length == 0 || 'is-invalid'"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="GlobeIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-domain"
                            v-model="site.domain"
                            placeholder="https://domain.com"
                            :state="errors.length > 0 ? false:null"
                            autocomplete="domain"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('sites.category')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('sites.category')"
                        label-for="i-category"
                        :class="errors.length == 0 || 'is-invalid'"
                      >
                        <v-select
                          id="i-category"
                          v-model="category"
                          :placeholder="$t('sites.choosecategory')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="categories"
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider
                      #default="{ errors }"
                      :name="$t('sites.country')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('sites.country')"
                        label-for="i-country"
                        :class="errors.length == 0 || 'is-invalid'"
                      >
                        <v-select
                          id="i-country"
                          v-model="country"
                          :placeholder="$t('sites.choosecountry')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="target_countries"
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                    <b-form-group
                      :label="$t('sites.advertorial_url')"
                      label-for="i-advertorial_url"
                    >
                      <b-input-group
                        class="input-group-merge"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="LinkIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-advertorial_url"
                          v-model="site.advertorial_url"
                          placeholder="https://domain.com/advertorial"
                          autocomplete="advertorial"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('sites.language')"
                      label-for="i-language"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('sites.language')"
                        rules="required"
                      >
                        <v-select
                          id="i-language"
                          v-model="language"
                          :placeholder="$t('sites.chooselanguage')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="languages"
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      v-if="authUserData.role.includes('admin')"
                      :label="`${$t('sites.blacklist')} (${$t('sites.blacklist-helptext')})`"
                      label-for="i-stop_words"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="stop_words"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length == 0 || 'is-invalid'"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="AlertTriangleIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-stop_words"
                            v-model="site.stop_words"
                            :placeholder="$t('sites.blacklist')"
                            :state="errors.length > 0 ? false:null"
                            autocomplete="stop_words"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      v-if="authUserData.role.includes('admin')"
                      label="Utm"
                      label-for="i-utm"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="utm"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length == 0 || 'is-invalid'"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="TagIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-utm"
                            v-model="site.utm"
                            placeholder="?utm_source=phoenix"
                            :state="errors.length > 0 ? false:null"
                            autocomplete="utm"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      :label="$t('sites.status')"
                      label-for="i-status"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('sites.status')"
                        rules="required"
                      >
                        <v-select
                          id="i-status"
                          v-model="status"
                          :placeholder="$t('sites.choosestatus')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="statusesTranslate"
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-end mt-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="mr-1"
                      @click="$router.go(-1)"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('Back') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="siteUpdate"
                    >
                      <span class="align-middle">{{ $t('form.save') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <b-tab v-if="authUserData.role.includes('admin')">
              <template #title>
                <feather-icon
                  icon="RssIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">RSS</span>
              </template>
              <b-card>
                <div class="head-card">
                  <div class="head-card-top">
                    <h4>RSS</h4>
                  </div>
                  <hr>
                </div>
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <b-form-checkbox
                        v-model="site.rss_enabled"
                        :selected="site.rss_enabled"
                      >
                        {{ $t('form.enable') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
                <template>
                  <b-row>
                    <b-col sm="12">
                      <b-form-group
                        :label="$t('sites.rss_version')"
                        label-for="i-rss_version"
                      >
                        <b-form-select
                          v-model="site.rss_version"
                          :options="rss_versions"
                          :disabled="!site.rss_enabled"
                        />
                      </b-form-group>
                      <b-form-group
                        :label="$t('sites.date_parse_string')"
                        label-for="i-date_parse_string"
                      >
                        <b-form-select
                          v-model="date_parse_format_selected"
                          class="mb-25"
                          :options="dateParseFormatKeys"
                          :disabled="!site.rss_enabled"
                        />
                        <template v-if="date_parse_format_selected !== 'Custom'">
                          <div class="alert alert-info">
                            <div class="alert-body">
                              {{ date_parse_string }}
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <b-form-input
                            v-model="date_parse_string_custom"
                            placeholder="Enter custom format"
                            :disabled="!site.rss_enabled"
                          />
                        </template>
                      </b-form-group>
                      <b-form-group
                        :label="$t('sites.rss_url')"
                        label-for="i-rss_url"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('sites.rss_url')"
                          :rules="`${site.rss_enabled ? 'required' : ''}`"
                        >
                          <b-input-group
                            class="input-group-merge"
                            :class="{
                              disabled: !site.rss_enabled,
                              'is-invalid': errors.length != 0
                            }"
                          >
                            <b-input-group-prepend is-text>
                              <feather-icon icon="LinkIcon" />
                            </b-input-group-prepend>
                            <b-form-input
                              id="i-rss_url"
                              v-model="site.rss_url"
                              placeholder="https://domain.com/rss.xml"
                              autocomplete="rss"
                              :state="errors.length > 0 ? false:null"
                              :disabled="!site.rss_enabled"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="checkRSS"
                      >
                        <span class="align-middle">{{ $t('Check') }}</span>
                        <feather-icon
                          icon="ChevronRightIcon"
                          class="ml-50"
                        />
                      </b-button>
                    </b-col>
                  </b-row>
                  <div
                    v-if="rss_test_loading"
                    class="d-flex justify-content-center mb-1"
                  >
                    <b-spinner label="Loading..." />
                  </div>
                  <b-row
                    v-if="!rss_test_loading && rss_test_result.length > 0"
                    class="mt-2"
                  >
                    <div class="col-12">
                      <div class="alert alert-success">
                        <div class="alert-body">
                          <span><strong>Success!</strong></span>
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="rss_post in rss_test_result"
                      :key="rss_post.date"
                      class="col-6 col-sm-4 col-md-3 col-lg-3"
                    >
                      <div class="card mb-2">
                        <div
                          v-if="rss_post.image"
                          style="max-height: 140px; position: relative; overflow: hidden;"
                        >
                          <img
                            :src="rss_post.image"
                            :alt="rss_post.title"
                            class="img-fluid card-img-top"
                          >
                        </div>
                        <div class="card-body">
                          <div
                            v-if="rss_post.title"
                          >
                            <a :href="rss_post.link">
                              {{ rss_post.title }}
                            </a>
                          </div>
                          <p
                            v-if="rss_post.date"
                            class="card-text"
                          >
                            {{ new Date(rss_post.date).toLocaleString('ru-RU') }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </b-row>
                  <b-row
                    v-if="!rss_test_loading && !rss_test_valid"
                    class="mt-2"
                  >
                    <div class="col-12">
                      <div class="alert alert-danger">
                        <div class="alert-body">
                          <span><strong>Error!</strong></span>
                        </div>
                      </div>
                    </div>
                  </b-row>
                </template>
                <b-row>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-end mt-2"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="mr-1"
                      @click="$router.go(-1)"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('Back') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="siteUpdate"
                    >
                      <span class="align-middle">{{ $t('form.save') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <b-tab v-if="authUserData.role.includes('admin')">
              <template #title>
                <feather-icon
                  icon="CpuIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">{{ $t('sites.parsing') }}</span>
              </template>
              <b-card>
                <div class="head-card">
                  <div class="head-card-top">
                    <h4>{{ $t('sites.parsing') }}</h4>
                  </div>
                  <hr>
                </div>
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <b-form-checkbox
                        v-model="site.enable_site_content_parsing"
                        :selected="site.enable_site_content_parsing"
                      >
                        {{ $t('form.enable') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>

                <template v-if="site.enable_site_content_parsing">
                  <b-row>
                    <b-col md="12">
                      <SiteUserParsing />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col md="6">
                      <h4 class="mb-2">
                        {{ $t('sites.params') }}
                      </h4>

                      <b-form-group class="mt-1">
                        <b-form-checkbox
                          v-model="parserDefault"
                          value="1"
                          @change="setParserDefault()"
                        >
                          {{ $t('form.default_settings') }}
                        </b-form-checkbox>
                      </b-form-group>

                      <template
                        v-if="!parserDefault.length"
                      >
                        <b-form-group
                          :label="$t('sites.encoding')"
                          label-for="i-encoding"
                        >
                          <b-form-select
                            v-model="encoding"
                            :options="encodings"
                          />
                        </b-form-group>

                        <b-form-group
                          :label="$t('sites.urlmask')"
                          label-for="i-url_mask"
                        >
                          <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text>
                              <span>[.*]</span>
                            </b-input-group-prepend>
                            <b-form-input
                              id="i-url_mask"
                              v-model="site.url_mask"
                              placeholder="regexp"
                            />
                          </b-input-group>
                        </b-form-group>

                        <b-form-group
                          :label="$t('sites.category_mask')"
                          label-for="i-category_mask"
                        >
                          <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text>
                              <span>[.*]</span>
                            </b-input-group-prepend>
                            <b-form-input
                              id="i-category_mask"
                              v-model="site.category_mask"
                              placeholder="regexp"
                            />
                          </b-input-group>
                        </b-form-group>

                        <b-form-group
                          :label="$t('sites.parser')"
                          label-for="i-parser"
                        >
                          <b-form-select
                            v-model="site.parser.title"
                            :options="parser"
                          />
                        </b-form-group>
                        <template v-if="site.parser.title === 'core.parser.processor.pquery.parser.PyQueryParser'">
                          <b-form-group
                            :label="$t('sites.title_mask')"
                            label-for="i-title_mask"
                          >
                            <b-input-group class="input-group-merge">
                              <b-input-group-prepend is-text>
                                <span>[.*]</span>
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-title_mask"
                                v-model="title_mask"
                                placeholder=""
                                @input="setParserFieldByKey(site.parser.fields, 'title_mask')"
                              />
                            </b-input-group>
                          </b-form-group>

                          <b-form-group
                            :label="$t('sites.image_mask')"
                            label-for="i-image_mask"
                          >
                            <b-input-group class="input-group-merge">
                              <b-input-group-prepend is-text>
                                <span>[.*]</span>
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-image_mask"
                                v-model="image_mask"
                                placeholder=""
                                @input="setParserFieldByKey(site.parser.fields, 'image_mask')"
                              />
                            </b-input-group>
                          </b-form-group>

                          <b-form-group
                            :label="$t('sites.image_prefix')"
                            label-for="i-image_prefix"
                          >
                            <b-input-group class="input-group-merge">
                              <b-input-group-prepend is-text>
                                <span>[.*]</span>
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-image_prefix"
                                v-model="image_prefix"
                                placeholder=""
                                @input="setParserFieldByKey(site.parser.fields, 'image_prefix')"
                              />
                            </b-input-group>
                          </b-form-group>
                        </template>
                        <template v-else-if="site.parser.title === 'core.parser.processor.rdability.parser.ReadabilityParser'">
                          <b-form-group
                            :label="$t('sites.image_prefix')"
                            label-for="i-image_prefix"
                          >
                            <b-input-group class="input-group-merge">
                              <b-input-group-prepend is-text>
                                <span>[.*]</span>
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-image_prefix"
                                v-model="image_prefix"
                                placeholder=""
                                @input="setParserFieldByKey(site.parser.fields, 'image_prefix')"
                              />
                            </b-input-group>
                          </b-form-group>
                        </template>

                        <b-form-group
                          :label="$t('sites.date')"
                          label-for="i-date_parser"
                        >
                          <b-form-select
                            v-model="site.date_parser.title"
                            :options="date_parser"
                          />
                        </b-form-group>
                        <template v-if="site.date_parser.title === 'core.parser.processor.date.parser.DefaultDateParser'">
                          <b-form-group
                            :label="$t('sites.date_mask')"
                            label-for="i-date_mask"
                          >
                            <b-input-group class="input-group-merge">
                              <b-input-group-prepend is-text>
                                <span>[.*]</span>
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-date_mask"
                                v-model="mask"
                                placeholder=""
                                @input="setParserFieldByKey(site.date_parser.fields, 'mask')"
                              />
                            </b-input-group>
                          </b-form-group>
                        </template>
                        <template v-else-if="site.date_parser.title === 'core.parser.processor.date.parser.UrlRegexDateParser'">
                          <b-form-group
                            :label="$t('sites.date_mask')"
                            label-for="i-date_mask"
                          >
                            <b-input-group class="input-group-merge">
                              <b-input-group-prepend is-text>
                                <span>[.*]</span>
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-date_mask"
                                v-model="mask"
                                placeholder=""
                                @input="setParserFieldByKey(site.date_parser.fields, 'mask')"
                              />
                            </b-input-group>
                          </b-form-group>

                          <b-form-group
                            :label="$t('sites.regex')"
                            label-for="i-date_regex"
                          >
                            <b-input-group class="input-group-merge">
                              <b-input-group-prepend is-text>
                                <span>[.*]</span>
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-date_regex"
                                v-model="regex"
                                placeholder=""
                                @input="setParserFieldByKey(site.date_parser.fields, 'regex')"
                              />
                            </b-input-group>
                          </b-form-group>
                        </template>
                        <template v-else-if="site.date_parser.title === 'core.parser.processor.date.parser.PyQueryDateParser'">
                          <b-form-group
                            :label="$t('sites.date_mask')"
                            label-for="i-date_mask"
                          >
                            <b-input-group class="input-group-merge">
                              <b-input-group-prepend is-text>
                                <span>[.*]</span>
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-date_mask"
                                v-model="mask"
                                placeholder=""
                                @input="setParserFieldByKey(site.date_parser.fields, 'mask')"
                              />
                            </b-input-group>
                          </b-form-group>
                          <b-form-group
                            :label="$t('sites.html_selector')"
                            label-for="i-date_mask2"
                          >
                            <b-input-group class="input-group-merge">
                              <b-input-group-prepend is-text>
                                <span>[.*]</span>
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-date_mask2"
                                v-model="date_mask"
                                placeholder=""
                                @input="setParserFieldByKey(site.date_parser.fields, 'date_mask')"
                              />
                            </b-input-group>
                          </b-form-group>
                          <b-form-group
                            :label="$t('sites.tag_attr')"
                            label-for="i-tag_attr"
                          >
                            <b-input-group class="input-group-merge">
                              <b-input-group-prepend is-text>
                                <span>[.*]</span>
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-tag_attr"
                                v-model="tag_attr"
                                placeholder=""
                                @input="setParserFieldByKey(site.date_parser.fields, 'tag_attr')"
                              />
                            </b-input-group>
                          </b-form-group>
                        </template>

                        <b-form-group
                          :label="$t('sites.language')"
                          label-for="i-language_parser"
                        >
                          <b-form-select
                            v-model="site.language_parser.title"
                            :options="language_parser"
                          />
                        </b-form-group>
                        <template v-if="site.language_parser.title === 'core.parser.processor.language.parser.UrlRegexLanguageParser'">
                          <b-form-group
                            :label="$t('sites.default_language')"
                            label-for="i-default_language"
                          >
                            <b-input-group class="input-group-merge">
                              <b-input-group-prepend is-text>
                                <span>[.*]</span>
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-default_language"
                                v-model="default_language"
                                placeholder=""
                                @input="setParserFieldByKey(site.language_parser.fields, 'default_language')"
                              />
                            </b-input-group>
                          </b-form-group>
                          <b-form-group
                            :label="$t('sites.regex')"
                            label-for="i-language_regex"
                          >
                            <b-input-group class="input-group-merge">
                              <b-input-group-prepend is-text>
                                <span>[.*]</span>
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-language_regex"
                                v-model="language_regex"
                                placeholder=""
                                @input="setParserFieldByKey(site.language_parser.fields, 'language_regex')"
                              />
                            </b-input-group>
                          </b-form-group>
                        </template>
                        <template v-else-if="site.language_parser.title === 'core.parser.processor.language.parser.PyQueryLanguageParser'">
                          <b-form-group
                            :label="$t('sites.default_language')"
                            label-for="i-default_language"
                          >
                            <b-input-group class="input-group-merge">
                              <b-input-group-prepend is-text>
                                <span>[.*]</span>
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-default_language"
                                v-model="default_language"
                                placeholder=""
                                @input="setParserFieldByKey(site.language_parser.fields, 'default_language')"
                              />
                            </b-input-group>
                          </b-form-group>
                          <b-form-group
                            :label="$t('sites.html_selector')"
                            label-for="i-language_mask"
                          >
                            <b-input-group class="input-group-merge">
                              <b-input-group-prepend is-text>
                                <span>[.*]</span>
                              </b-input-group-prepend>
                              <b-form-input
                                id="i-language_mask"
                                v-model="language_mask"
                                placeholder=""
                                @input="setParserFieldByKey(site.language_parser.fields, 'language_mask')"
                              />
                            </b-input-group>
                          </b-form-group>
                        </template>
                      </template>
                    </b-col>
                    <b-col md="6">
                      <h4 class="mb-2">
                        {{ $t('sites.testing') }}
                      </h4>
                      <b-form-group
                        :label="$t('sites.urlcheck')"
                        label-for="i-checking"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="Link2Icon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-checking"
                            v-model="checking"
                            :placeholder="$t('sites.urlcheck')"
                            autocomplete="checking"
                          />
                        </b-input-group>
                      </b-form-group>

                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="checkUrl"
                      >
                        <span class="align-middle">{{ $t('Check') }}</span>
                        <feather-icon
                          icon="ChevronRightIcon"
                          class="ml-50"
                        />
                      </b-button>
                      <div
                        v-for="(result, idx) in Object.keys(checkResult)"
                        :key="idx"
                      >
                        <div
                          v-if="checkResult[result] !== undefined && checkResult[result] !== null && Object.keys(checkResult[result]).length !== 0"
                          class="ibox mt-2"
                          :class="siteTest ? result : 'warning'"
                        >
                          <div class="header">
                            <feather-icon
                              icon="AlertCircleIcon"
                              width="24"
                              height="24"
                            />
                            <strong class="mr-auto">{{ siteTest ? result : 'warning' }}</strong>
                          </div>
                          <div class="body">
                            <div
                              class="mb-1"
                              :class="{'text-danger': !checkResult[result].image }"
                            >
                              <strong class="mr-25">Image:</strong>
                              <b-img
                                v-if="checkResult[result].image"
                                :src="checkResult[result].image"
                                :alt="checkResult[result].title"
                                fluid
                              />
                              <feather-icon
                                v-if="!checkResult[result].image"
                                icon="XIcon"
                                size="16"
                                class="text-danger"
                              />
                            </div>
                            <div
                              class="mb-1"
                              :class="{'text-danger': !checkResult[result].title }"
                            >
                              <strong>Title:</strong> {{ checkResult[result].title }}
                              <feather-icon
                                v-if="!checkResult[result].title"
                                icon="XIcon"
                                size="16"
                                class="text-danger"
                              />
                            </div>
                            <div
                              v-if="checkResult[result].date"
                              class="mb-1"
                              :class="{'text-danger': !checkResult[result].date }"
                            >
                              <strong>Date:</strong> {{ getFormattedDate(new Date(checkResult[result].date), true) }}
                              <feather-icon
                                v-if="!checkResult[result].date"
                                icon="XIcon"
                                size="16"
                                class="text-danger"
                              />
                            </div>
                            <div
                              :class="{'text-danger': !checkResult[result].description }"
                            >
                              <strong>Description:</strong> {{ checkResult[result].description }}
                              <feather-icon
                                v-if="!checkResult[result].description"
                                icon="XIcon"
                                size="16"
                                class="text-danger"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </template>
                <b-row>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-end mt-2"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="mr-1"
                      @click="$router.go(-1)"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('Back') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="siteUpdate"
                    >
                      <span class="align-middle">{{ $t('form.save') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <b-tab>
              <!-- title -->
              <template #title>
                <feather-icon
                  icon="RepeatIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">
                  {{ $t('sites.exchange') }}
                </span>
                <span class="badge badge-danger badge-pill badge-up"> new </span>
              </template>
              <b-card>
                <div class="head-card">
                  <div class="head-card-top">
                    <h4>{{ $t('sites.exchange') }}</h4>
                    <!-- <feather-icon
                      v-b-tooltip.hover.top="$t('Close')"
                      icon="XIcon"
                      size="18"
                      class="toggle-close cursor-pointer"
                      @click="$router.go(-1)"
                    /> -->
                  </div>
                  <hr>
                </div>
                <div
                  v-if="userSites.length"
                  class="d-flex justify-content-between align-items-center flex-wrap"
                >
                  <div
                    v-for="userSite in userSites"
                    :key="userSite._id"
                    class="link-card"
                  >
                    <label
                      class="label"
                      :for="userSite._id"
                    >
                      {{ userSite.domain }}
                      <div
                        v-if="userSite.status === 'active'"
                        class="text-success"
                      >
                        <feather-icon
                          icon="CheckIcon"
                          size="16"
                        />
                        {{ $t(`selects.statuses.${userSite.status}`) }}
                      </div>
                      <div
                        v-else-if="userSite.status === 'inactive'"
                        class="text-danger"
                      >
                        <feather-icon
                          icon="XIcon"
                          size="16"
                        />
                        {{ $t(`selects.statuses.${userSite.status}`) }}
                      </div>
                    </label>
                    <b-form-checkbox
                      :id="userSite._id"
                      v-model="selectedUserSites"
                      :value="userSite._id"
                    />
                  </div>
                </div>
                <div v-else>
                  <p>{{ $t('sites.noexchange') }}</p>
                </div>
                <b-row>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-end mt-2"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="mr-1"
                      @click="$router.go(-1)"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('Back') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="siteUpdate"
                    >
                      <span class="align-middle">{{ $t('form.save') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <b-tab v-if="authUserData.role.includes('admin')">
              <!-- title -->
              <template #title>
                <feather-icon
                  icon="ShieldIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">{{ $t('Administrator') }}</span>
              </template>
              <b-card>
                <div class="head-card">
                  <div class="head-card-top">
                    <h4>{{ $t('sites.adminsettings') }}</h4>
                    <!-- <feather-icon
                      v-b-tooltip.hover.top="$t('Close')"
                      icon="XIcon"
                      size="18"
                      class="toggle-close cursor-pointer"
                      @click="$router.go(-1)"
                    /> -->
                  </div>
                  <hr>
                </div>
                <b-row>
                  <b-col md="6">
                    <b-form-group class="mt-1">
                      <b-form-checkbox
                        v-model="site.is_moderate"
                      >
                        {{ $t('sites.moderated') }}
                      </b-form-checkbox>
                    </b-form-group>

                    <b-form-group
                      :label="$t('sites.materialslifetime')"
                      label-for="i-article_ttl"
                    >
                      <b-input-group
                        class="input-group-merge"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="CalendarIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-article_ttl"
                          v-model="site.article_ttl"
                          placeholder="7"
                          autocomplete="article_ttl"
                        />
                      </b-input-group>
                    </b-form-group>

                    <b-form-group
                      :label="$t('sites.timezone')"
                      label-for="i-timezone"
                    >
                      <b-input-group
                        class="input-group-merge"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="ClockIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-timezone"
                          v-model.number="site.timezone"
                          placeholder="-3"
                          autocomplete="timezone"
                        />
                      </b-input-group>
                    </b-form-group>

                    <b-form-group
                      :label="$t('sites.payment_method')"
                      label-for="i-payment"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="FileTextIcon" />
                        </b-input-group-prepend>
                        <b-form-textarea
                          id="i-payment"
                          v-model="site.payment_method"
                          :placeholder="$t('sites.payment_method')"
                        />
                      </b-input-group>
                    </b-form-group>

                    <b-form-group
                      :label="$t('page.features.impressionRate')"
                      label-for="i-impression_size_alert"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="impression_size_alert"
                        rules="integer"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length == 0 || 'is-invalid'"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="BarChart2Icon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-impression_size_alert"
                            v-model="site.impression_size_alert"
                            type="number"
                            placeholder="500"
                            :state="errors.length > 0 ? false:null"
                            autocomplete="impression_size_alert"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      :label="$t('sites.tags')"
                      label-for="i-tags"
                    >
                      <b-input-group
                        class="input-group-merge"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="TagIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-tag"
                          v-model.number="site.tags"
                          :placeholder="$t('sites.tags')"
                          autocomplete="tags"
                        />
                      </b-input-group>
                    </b-form-group>

                    <b-form-group>
                      <b-form-checkbox
                        v-model="site.advanced_stats_enabled"
                        :selected="site.advanced_stats_enabled"
                      >
                        {{ $t('sites.advanced_stats_enabled') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <h4 class="mb-2">
                      {{ $t('sites.affiliatesettings') }}
                    </h4>
                    <b-form-group
                      :label="$t('sites.affiliateuserid')"
                      label-for="i-affiliate_user_id"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-affiliate_user_id"
                          v-model="site.affiliate_user_id"
                          placeholder="579205c94f32fe3c87f43719"
                          autocomplete="affiliate_user_id"
                        />
                      </b-input-group>
                    </b-form-group>

                    <b-form-group
                      :label="$t('sites.affiliateearn')"
                      label-for="i-affiliate_earn_percent"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="PercentIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-affiliate_earn_percent"
                          v-model="site.affiliate_earn_percent"
                          placeholder="10"
                          autocomplete="affiliate_earn_percent"
                        />
                      </b-input-group>
                    </b-form-group>

                    <b-form-group
                      :label="$t('sites.agency_rate')"
                      label-for="i-agency_rate"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="PercentIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-agency_rate"
                          v-model="site.agency_rate"
                          placeholder="10"
                          autocomplete="agency_rate"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-end mt-2"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="mr-1"
                      @click="$router.go(-1)"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('form.back') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="siteUpdate"
                    >
                      <span class="align-middle">{{ $t('form.save') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <b-tab v-if="authUserData.role.includes('admin')">
              <template #title>
                <feather-icon
                  icon="BellIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Send push (Demo)</span>
              </template>
              <b-card>
                <div class="head-card">
                  <div class="head-card-top">
                    <h4>Send message</h4>
                    <!-- <feather-icon
                      v-b-tooltip.hover.top="$t('Close')"
                      icon="XIcon"
                      size="18"
                      class="toggle-close cursor-pointer"
                      @click="$router.go(-1)"
                    /> -->
                  </div>
                  <hr>
                </div>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('web-push.title')"
                      label-for="i-push-title"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('web-push.title')"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length == 0 || 'is-invalid'"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="LayoutIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-push-title"
                            v-model="webpush.title"
                            placeholder="Push title"
                            :state="errors.length > 0 ? false:null"
                            autocomplete="title"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      :label="$t('web-push.message')"
                      label-for="i-push-message"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="FileTextIcon" />
                        </b-input-group-prepend>
                        <b-form-textarea
                          id="i-push-message"
                          v-model="webpush.message"
                          :placeholder="$t('web-push.message')"
                        />
                      </b-input-group>
                    </b-form-group>
                    <b-form-group
                      :label="$t('web-push.url')"
                      label-for="i-push-url"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('web-push.url')"
                        :rules="{ regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,10}(:[0-9]{1,5})?(\/.*)?$/ }"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length == 0 || 'is-invalid'"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="LinkIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-push-url"
                            v-model="webpush.url"
                            placeholder="Url (redirect after click)"
                            :state="errors.length > 0 ? false:null"
                            autocomplete="domain"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      :label="$t('web-push.image')"
                      label-for="i-push-image"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('web-push.image')"
                        :rules="{ regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,10}(:[0-9]{1,5})?(\/.*)?$/ }"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length == 0 || 'is-invalid'"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="ImageIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-push-image"
                            v-model="webpush.image_url"
                            placeholder="Push image Url"
                            :state="errors.length > 0 ? false:null"
                            autocomplete="domain"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="12"
                    class="mt-2"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      :disabled="invalid || webpushButtonDisabled"
                      @click="sendPush(invalid)"
                    >
                      <span class="align-middle">{{ $t('form.send') }}</span>
                      <feather-icon
                        icon="SendIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
          </b-tabs>
        </validation-observer>
      </b-form>
    </div>
  </div>
</template>

<script>
import SiteToolbar from '@/views/dashboard/sites/SiteToolbar.vue'
import useJwt from '@/auth/jwt/useJwt'
import options from '@/libs/options'
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BTabs,
  BTab,
  BCard,
  BForm,
  BFormGroup,
  BImg,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BFormTextarea,
  BSpinner,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  required,
  integer,
  min,
} from '@validations'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import { getUserData } from '@/auth/utils'
import getFormattedDate from '@/utils/date-formatter'
import SiteUserParsing from './SiteUserParsing.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
    Breadcrumbs,
    BForm,
    BFormGroup,
    BImg,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    vSelect,
    BButton,
    SiteUserParsing,
    UserToolbar,
    SiteToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      visible: true,
      account: {},
      userSites: [],
      selectedUserSites: [],
      min,
      required,
      integer,
      checking: '',
      site: {},
      breadcrumbItems: [],
      category: {},
      country: {},
      language: {},
      status: {},
      encoding: 'auto',
      ...options,
      authUserData: getUserData(),
      parserDefault: [],
      checkResult: {
        success: {},
        error: {},
      },
      siteTest: true,
      title_mask: '',
      image_mask: '',
      image_prefix: '',
      mask: '',
      regex: '',
      date_mask: '',
      tag_attr: '',
      language_regex: '',
      default_language: '',
      language_mask: '',
      getFormattedDate,
      date_parse_formats: {
        Layout: '01/02 03:04:05PM \'06 -0700',
        ANSIC: 'Mon Jan _2 15:04:05 2006',
        UnixDate: 'Mon Jan _2 15:04:05 MST 2006',
        RubyDate: 'Mon Jan 02 15:04:05 -0700 2006',
        RFC822: '02 Jan 06 15:04 MST',
        RFC822Z: '02 Jan 06 15:04 -0700',
        RFC850: 'Monday, 02-Jan-06 15:04:05 MST',
        RFC1123: 'Mon, 02 Jan 2006 15:04:05 MST',
        RFC1123Z: 'Mon, 02 Jan 2006 15:04:05 -0700',
        RFC3339: '2006-01-02T15:04:05Z07:00',
        RFC3339Nano: '2006-01-02T15:04:05.999999999Z07:00',
        Kitchen: '3:04PM',
        Stamp: 'Jan _2 15:04:05',
        StampMilli: 'Jan _2 15:04:05.000',
        StampMicro: 'Jan _2 15:04:05.000000',
        StampNano: 'Jan _2 15:04:05.000000000',
        DateTime: '2006-01-02 15:04:05',
        DateOnly: '2006-01-02',
        TimeOnly: '15:04:05',
        Custom: null,
      },
      date_parse_format_default: 'Layout',
      date_parse_format_selected: '',
      date_parse_string: '',
      date_parse_string_custom: '',
      rss_test_valid: true,
      rss_test_result: [],
      rss_test_loading: false,
      webpush: {
        title: '',
        message: '',
        url: '',
        image_url: '',
      },
      webpushButtonDisabled: false,
    }
  },
  computed: {
    dateParseFormatKeys() {
      return Object.keys(this.date_parse_formats)
    },
    statusesTranslate() {
      return this.statuses.map(s => {
        const item = {}
        item.value = s.value
        item.text = this.$t(`selects.statuses.${s.value}`)
        return item
      })
    },
  },
  watch: {
    date_parse_format_selected: {
      handler(newVal) {
        if (newVal !== 'Custom') {
          this.date_parse_string = this.date_parse_formats[newVal]
        }
      },
      immediate: false,
    },
  },
  created() {
    this.getSiteData()
    this.fetchAccountSites()
  },
  methods: {
    async sendPush(isInvalid) {
      if (isInvalid) {
        return
      }

      this.$refs.validationSiteRules.validate().then(async success => {
        if (success) {
          this.webpushButtonDisabled = true
          const myHeaders = new Headers()
          myHeaders.append('Content-Type', 'application/json')

          const raw = JSON.stringify({
            siteId: this.$route.params.siteId,
            title: this.webpush.title,
            message: this.webpush.message,
            url: this.webpush.url,
            image: this.webpush.image_url,
          })

          const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
          }

          await fetch('https://phoenix-widget.com/wp/send-notification', requestOptions)
            .then(response => response.json())
            .then(result => {
              if (result.status === 'success') {
                setTimeout(() => {
                  this.webpushButtonDisabled = false
                }, 3000)
              }

              if (result.status === 'error') {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: result.message,
                  },
                })
                setTimeout(() => {
                  this.webpushButtonDisabled = false
                }, 3000)
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error,
                },
              })
            })
        } else {
          this.webpushButtonDisabled = true
        }
      })
    },
    findSelectedKeyByValue() {
      let selectedKey = 'Custom'
      this.date_parse_string = this.site.date_parse_string
      Object.keys(this.date_parse_formats).forEach(key => {
        if (this.date_parse_formats[key] === this.date_parse_string) {
          this.date_parse_string = this.site.date_parse_string
          selectedKey = key
        }
      })
      if (selectedKey === 'Custom') {
        this.date_parse_string_custom = this.site.date_parse_string
      }

      if (this.date_parse_string === undefined) {
        selectedKey = this.date_parse_format_default
      }

      this.date_parse_format_selected = selectedKey
    },
    async getSiteData() {
      const responseData = await useJwt.getSite(this.$route.params.siteId)
      this.site = responseData.data.site || []

      if (this.site.category !== '') {
        const categoryText = this.site.category.charAt(0).toUpperCase() + this.site.category.slice(1)
        this.category = { value: this.site.category, text: categoryText }
      } else {
        this.category = null
      }

      if (this.site.country !== '') {
        const countryText = this.site.country.charAt(0).toUpperCase() + this.site.country.slice(1)
        this.country = { value: this.site.country, text: countryText }
      } else {
        this.country = null
      }

      if (this.site.language !== '') {
        this.language = { value: this.site.language, text: this.site.language }
      } else {
        this.language = null
      }

      if (this.site.status !== '') {
        this.status = { value: this.site.status, text: this.$t(`selects.statuses.${this.$t(this.site.status)}`) }
      }

      if (this.site.encoding !== '') {
        this.encoding = this.site.encoding
      }

      if (this.site.agency_rate === undefined) {
        this.site.agency_rate = 0
      }

      if (this.site.parser.title === 'core.parser.processor.opengraph.parser.OpenGraphParser') {
        this.parserDefault.push(1)
      }

      if ('exchange_sites_ids' in this.site) {
        this.selectedUserSites = this.site.exchange_sites_ids
      }

      this.site.parser.fields.forEach(f => {
        this[f.key] = this.parserFieldValueByKey(f.key)
      })

      this.site.date_parser.fields.forEach(f => {
        this[f.key] = this.dateParserFieldValueByKey(f.key)
      })

      this.site.language_parser.fields.forEach(f => {
        this[f.key] = this.languageParserFieldValueByKey(f.key)
      })
      this.findSelectedKeyByValue()
      this.getUserData()
    },
    parserFieldValueByKey(key) {
      const idx = this.site.parser.fields.findIndex(i => i.key === key)
      return this.site.parser.fields[idx] !== undefined ? this.site.parser.fields[idx].value : ''
    },
    dateParserFieldValueByKey(key) {
      const idx = this.site.date_parser.fields.findIndex(i => i.key === key)
      return this.site.date_parser.fields[idx] !== undefined ? this.site.date_parser.fields[idx].value : ''
    },
    languageParserFieldValueByKey(key) {
      const idx = this.site.language_parser.fields.findIndex(i => i.key === key)
      return this.site.language_parser.fields[idx] !== undefined ? this.site.language_parser.fields[idx].value : ''
    },
    setParserFieldByKey(arrayFields, key) {
      const idx = arrayFields.findIndex(i => i.key === key)
      if (idx === -1) {
        arrayFields.push({ key, value: this[key] })
      } else {
        arrayFields[idx] = { key, value: this[key] }
      }
    },
    async checkUrl() {
      const parserData = {
        url: this.checking,
        encoding: this.site.encoding,
        category_mask: this.site.category_mask,
        parser: this.site.parser,
        dateParser: this.site.date_parser,
        languageParser: this.site.language_parser,
      }
      await useJwt.testSiteParser(parserData).then(result => {
        this.checkResult.success = result.data
        if (Object.keys(result.data).length !== 0) {
          this.siteTest = true
          Object.keys(result.data).forEach(key => {
            if (key === 'title' || key === 'image' || key === 'description') {
              if (result.data[key] === null) {
                this.siteTest = false
              }
            }
          })
        }
      }).catch(error => {
        this.checkResult.error = error.data
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    async checkRSS() {
      if (this.site.rss_url !== '') {
        this.rss_test_loading = true
        const dataObject = {
          feed_url: this.site.rss_url,
          rss: this.site.rss_version,
          date_format: this.date_parse_format_selected !== 'Custom' ? this.date_parse_string : this.date_parse_string_custom,
        }
        await useJwt.testSiteRss(dataObject)
          .then(result => {
            if (result.data.length > 0) {
              this.rss_test_valid = true
              this.rss_test_result = result.data
              this.rss_test_loading = false
            }
          })
          .catch(error => {
            this.rss_test_loading = false
            this.rss_test_valid = false
            this.rss_test_result = []
            if (error.response && error.response.status === 422) {
              if (error.response.data.errors.length) {
                error.response.data.errors.forEach(err => {
                  const field = err.source.pointer.split('/')
                  this.$store.dispatch(
                    'error-store/showNotify',
                    {
                      title: `Error [${field[field.length - 1]}]`,
                      msg: err.detail,
                      variant: 'danger',
                    },
                    { root: true },
                  )
                })
              }
            }
            if (error.response && (error.response.status === 500 || error.response.status === 400)) {
              this.$store.dispatch(
                'error-store/showNotify',
                {
                  title: error.response.statusText,
                  msg: error.message,
                  variant: 'danger',
                },
                { root: true },
              )
            }
          })
      }
    },
    siteUpdate() {
      this.$refs.validationSiteRules.validate().then(success => {
        if (success) {
          this.site.category = this.category.value
          this.site.country = this.country.value
          this.site.language = this.language.value
          this.site.status = this.status.value
          this.site.encoding = this.encoding
          this.site.timezone = this.site.timezone || 0
          this.site.exchange_sites_ids = this.selectedUserSites

          if (this.site.rss_enabled) {
            if (this.date_parse_format_selected === 'Custom') {
              this.site.date_parse_string = this.date_parse_string_custom
            } else {
              this.site.date_parse_string = this.date_parse_string
            }
          }

          useJwt.setSite(this.$route.params.siteId, { site: this.site }).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Site saved',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          }).catch(error => {
            if (error.response && error.response.status === 422) {
              if (error.response.data.errors.length) {
                error.response.data.errors.forEach(err => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Error',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: err.detail,
                    },
                  })
                  const field = err.source.pointer.split('/')
                  this.$refs.validationSiteRules.setErrors({ [field[field.length - 1]]: err.detail })
                })
              }
            }
          })
        }
      })
    },
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || {}
      this.setBreabcrumbs()
    },
    async fetchAccountSites() {
      this.userSites = []
      const responseAccountData = await useJwt.accountSites(this.$route.params.userId)
      this.userSites = responseAccountData.data.sites.filter(s => s._id !== this.$route.params.siteId) || []
    },
    setParserDefault() {
      if (this.parserDefault.includes('1')) {
        this.site.parser.title = 'core.parser.processor.opengraph.parser.OpenGraphParser'
      }
    },
    setBreabcrumbs() {
      if (this.authUserData.role.includes('admin')) {
        this.breadcrumbItems.push({
          text: 'users',
          to: '/users',
        })
      }
      this.breadcrumbItems.push(
        {
          text: this.account.name,
          to: `/users/${this.$route.params.userId}`,
        },
        {
          text: 'sites',
          to: `/users/${this.$route.params.userId}/sites`,
        },
        {
          text: this.site.title,
          to: `/users/${this.$route.params.userId}/sites/${this.$route.params.siteId}`,
        },
        {
          text: this.$t('Settings'),
          active: true,
        },
      )
    },
  },
}
</script>
